import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Layout from "../layouts"
import SEO from "../components/seo"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Tabs extends React.Component {
  constructor(props) {
    super();
    this.state = {
      active: 0
    }
  }
  
  select = (i) => {
    let _this = this;
    return function() {
      _this.setState({
        active: i
      });
    }
  }
  
  renderTabs = () => {
    return React.Children.map(this.props.children, (item, i) => {
      if (i%2 === 0) {
        let active = this.state.active === i ? 'active' : '';
        return <div onClick={this.select(i)} className={`${active} tab`}><h6>{item}</h6></div>;
      }
    });
  }
  
  renderContent() {
    return React.Children.map(this.props.children, (item, i) => {
      if (i-1 === this.state.active) {
        return <div className='content'>{item}</div>;
      } else {
        return;
      }
    });
  }
  
  render() {
    return (
      <div className="tabs">
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    );
  }
}

class ProductTemplate extends Component {
  constructor(props) {
    super();
    this.state = {
      active: 0,
      open: false,
      modal: "trailer",
      title: "",
      sliderSettings: {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        draggable: false
      }
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePoster = this.togglePoster.bind(this);
    this.toggleGallery = this.toggleGallery.bind(this);
  }
  componentDidMount() {
    const query = this.props.data.wordpressWpProducts
    this.setState({
      title: this.htmlDecode(query.title)
    })
     
  }
  select(i) {
    let _this = this;
    return function() {
      _this.setState({
        active: i
      });
    }
  }
  htmlDecode(str){
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }
  toggleModal() {
    const currentState = this.state.open;
    this.setState({ open: !currentState, modal: "trailer" });
  }
  toggleGallery(slide) { 
    const {open, sliderSettings} = this.state;
    var updateState = !open
    var updateSettings = sliderSettings
    updateSettings.initialSlide = slide
    this.setState({ 
      open: updateState,
      modal: "gallery",
      sliderSettings: updateSettings
    });
  }
  togglePoster() {
    const {open} = this.state;
    var updateState = !open
    
    this.setState({ 
      open: updateState,
      modal: "poster"
    });
  }
  render() {
    const { modal, sliderSettings } = this.state
    const query = this.props.data.wordpressWpProducts
    const product = query.acf
    // var year = ""
    var yea = [], lan = [], gen= [], cou = [];
    if (query.categories && query.categories.length > 0) {
      query.categories.map(function(cat){
        switch (cat.parent_element.name) {
          case "Year":
            yea.push(" | ");
            yea.push(<a key={cat.name} href={"/catalog/?filter="+ cat.slug} target="_blank" rel="noreferrer">{cat.name}</a>);
            break;
          case "Genre":
            gen.push(" | ");
            gen.push(<a key={cat.name} href={"/catalog/?filter="+ cat.slug} target="_blank" rel="noreferrer">{cat.name}</a>);
            break;
          case "Country":
            cou.push(" | ");
            cou.push(<a key={cat.name} href={"/catalog/?filter="+ cat.slug} target="_blank" rel="noreferrer">{cat.name}</a>);
            break;
          case "Language":
            lan.push(" | ");
            lan.push(<a key={cat.name} href={"/catalog/?filter="+ cat.slug} target="_blank" rel="noreferrer">{cat.name}</a>);
            break;
          default:
        }
        return true;
      });
    }
    
    const poster = product.product_poster_image? product.product_poster_image.localFile.childImageSharp.fluid: product.product_stills[0].still_image.localFile.childImageSharp.fluid;
    const stills = product.product_stills
    let heroIndex = !product.product_hero && stills.length > 1 ? Math.floor(Math.random() * stills.length): 0; 
    
    const stillHero = stills.length > 0 ? stills[heroIndex].still_image.localFile.childImageSharp.fluid: null;
    const hero = product.product_hero ? product.product_hero.localFile.childImageSharp.fluid: stillHero;
    var align = "center ";
    align += product.product_hero_align ? product.product_hero_align.toLowerCase(): "center"
    const trailer = product.product_vimeo_id !== "" ? "https://player.vimeo.com/video/"+ product.product_vimeo_id +"?title=0&byline=0&portrait=0&autoplay=1": null;
    return (
      <Layout classes="product">
        <SEO title={this.state.title} />
        <section className="section hero hero--product">
          <div className="container">
            <div className="hero__item">
              {hero !== null? <Img imgStyle={{objectPosition: align }} src={hero.src} fluid={hero} />: null}
              <div className="product__laurels">
                {product.laurels? product.laurels.map((l, index) => {
                  if (l.laurel) {
                    return <div className="product__laurel" key={"laurel" + index}>
                      {l.laurel.localFile.childImageSharp? <Img imgStyle={{objectFit: "contain" }} src={l.laurel.localFile.childImageSharp.fluid.src} fluid={l.laurel.localFile.childImageSharp.fluid} />: <img src={l.laurel.localFile.publicURL} alt={"laurel_" + index}/>}
                    </div>
                  } 
                  return true
                }): null}
              </div>
            </div>
          </div>
        </section>
        <section className="section product-info">
          <div className="container">
            <div className="product__left">
              <div className="product__poster" onClick={this.togglePoster}>
                {poster !== null? <Img src={poster.src} fluid={poster} />: null}
              </div>
              <div className="product__buttons">
                {trailer !== null ? 
                  <div className="product__button" onClick={this.toggleModal}>Watch Trailer</div>:
                  null
                }
                {product.product_press_kit ?
                  <a className="product__button" href={product.product_press_kit } target="_blank" rel="noreferrer">Press Kit <span className="icon-file-text-o"></span></a>: null
                }
                {product.product_imdb ?
                  <a className="product__button product__button--imdb" href={"http://imdb.com" + product.product_imdb.split('imdb.com').pop() } target="_blank" rel="noreferrer"><span>Find this On</span> <span className="icon-imdb"></span></a>: null
                }
                {product.product_rotten_tomatoes ?
                  <a className="product__button product__button--rt" href={"https://rottentomatoes.com" + product.product_rotten_tomatoes.split('rottentomatoes.com').pop() } target="_blank" rel="noreferrer"><span className="icon-rt"></span></a>: null
                }
              </div>
             
            </div>
            <div className="product__details">
              <h1>{this.state.title}</h1>
              <h4><span className="label">Directed By</span> {product.product_director}</h4>  
              <h4>
                {product.product_duration !== null? <span className="duration">{product.product_duration + "min"}</span>:  null } {yea.length > 0 ? yea.map(year =>  year): null}{cou.length > 0 ? cou.map(country =>  country): null}{lan.length > 0? lan.map((language) =>  language): null}{gen.length > 0? gen.map(genre =>  genre ): null}
              </h4>
            </div>
            <div className="product__content">
              <Tabs>
                Details
                <div className="product-details">
                  <div className="product-description">{ product.product_description }</div>          
                  <div className="product-detail">
                    <h6 >Crew</h6>
                    {product.product_crew.map((crew, index) =>  {
                      let role = crew.product_crew_role
                      var name = typeof crew.product_crew_name === "string" ? crew.product_crew_name.replace(/\[I\]/g, "<i>").replace(/\[\/I\]/g, "</i>").replace(/\[B\]/g, "<b>").replace(/\[\/B\]/g, "</b>"): null
                      
                      return <div className="detail" key={"crew" + index}><span key={"crew-role" + index} className="detail__role">{role}:</span> <span key={"crew-name" + index} className="detail__name" dangerouslySetInnerHTML={{ __html: name}}></span></div>
                    }) }
                  </div>
                  {product.product_cast !== null ?
                    <div className="product-detail">
                      <h6>Cast</h6>
                      {product.product_cast.map((crew, index) =>{
                         var name = crew.product_cast_member.replace(/\[I\]/g, "<i>")
                         name = name.replace(/\[\/I\]/g, "</i>")
                        return <div className="detail" key={"cast" + index} dangerouslySetInnerHTML={{ __html: name}}></div>}) }
                    </div>:
                  null}
                  {product.product_festivals !== null ?
                    <div className="product-detail">
                      <h6>Festivals</h6>
                      {product.product_festivals.map((fest, index) => <div key={"fest" + index}>{fest.product_festival}</div>)}
                    </div>:
                  null}
                 
                  {product.product_awards?
                    <div className="product-detail">
                      <h6>Awards</h6>
                      {product.product_awards.map((award, i) => {
                        if (award) {
                          return <div key={"product_award_"+ i}>
                            {award.product_award}
                          </div>
                        } 
                        return true
                      })}
                    </div>:
                  null}
                </div>
                Press
                <div className="product-press">
                  {product.product_reviews !== null ? product.product_reviews.map((review, index) => {
                    var content = review.product_review_content.replace(/\[I\]/g, "<i>")
                    content = content.replace(/\[\/I\]/g, "</i>")
                    return <div key={"quote_" + index}>
                      <div key={"quote_" + index} className="product-quote" dangerouslySetInnerHTML={{__html: content}} ></div><h6 key={"pub" + index}>{review.product_review_title}</h6>
                    </div>}) :<div className="coming-soon"> Coming Soon...</div>}
                </div>
                Stills
                <div className="product-stills">
                  {product.product_stills !== null ? product.product_stills.map((still, index) => <div key={"still_" + index}  onClick={(event) => this.toggleGallery(index)} ><Img src={still.still_image.localFile.childImageSharp.fluid.src} fluid={still.still_image.localFile.childImageSharp.fluid} imgStyle={{objectFit: 'cover'}}/></div>) :<div className="coming-soon"> Coming Soon...</div>}
                </div>
              </Tabs>
              </div>
          </div>
        </section>
        {trailer !== null ||  product.product_stills.length > 0  ? 
          <div id="modal-container" className={this.state.open ? "open": null} >
            <div className={modal !== "gallery" ? "modal-background": "modal-background gallery"}>
              <div className="modal">
                {modal === "trailer" ?
                  <div className="vimeo-wrapper">
                    <iframe title="trailer" src={this.state.open? trailer: ""} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen />
                  </div>
                : null}
                {modal === "gallery" ?
                  <div className="gallery-wrapper">
                    <Slider {...sliderSettings}>
                      {product.product_stills !== null ? product.product_stills.map((still, index) => <Img className="modal__image" key={"modal-still" + index} src={still.still_image.localFile.childImageSharp.fluid.src} fluid={still.still_image.localFile.childImageSharp.fluid} />) :null}

                    </Slider>
                  </div>
                : null}
                {modal === "poster" ?
                  <div className="poster-wrapper">
                    {poster !== null? <Img src={poster.src} fluid={poster} />: null}
                  </div>
                : null}
              </div>
              <div className="modal__close icon-cross" onClick={this.toggleModal}></div>
            </div>
          </div>:
          null
        }
      </Layout>
    )
  }
}

ProductTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default ProductTemplate

export const pageQuery = graphql`
  query($id: String!) {
      wordpressWpProducts(id: {eq: $id}) {
          title
          acf {
            laurels {
              laurel {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 680) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            product_awards {
              product_award
            }
            product_description
            product_director
            product_duration
            product_imdb
            product_vimeo_id
            product_website
            product_rotten_tomatoes
            product_hero {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            product_hero_align
            product_poster_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            product_crew {
              product_crew_name
              product_crew_role
            }
            product_cast {
              product_cast_member
            }
            product_festivals {
              product_festival
            }
            product_press_kit 
            product_reviews {
              product_review_content
              product_review_title
            }
            product_stills {
              still_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          categories {
            name
            slug
            parent_element {
              name
              slug
            }
          }
        }
      }
`
